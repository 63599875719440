import React, { FC, useCallback, useMemo, useState } from 'react';
// @ts-ignore
import { branch } from 'baobab-react/higher-order';

import {
  institutionsSelector,
  positionsSelector,
} from '../../../../store/struct/selectors';

import { SUBTYPES } from '../../../../components/form/input';
import Button, { THEMES } from '../../../../components/button';
import Form, { TYPES as FORM_TYPES } from '../../../../components/form';

import CLIENT_STRUCT from '../../../../store/struct/entities/client';
import POSITION_STRUCT from '../../../../store/struct/entities/position';
import INSTITUTION_STRUCT from '../../../../store/struct/entities/institution';

import styles from './index.module.scss';

import {
  GameClient,
  GameForm,
  Participant,
  PARTICIPANT_ROLE,
} from '../../../../types';

interface GameClientsProps {
  formRef: React.RefObject<any>;
  institutions: any;
  positions: any;
  participants: Participant[];
  gameForms: GameForm[];
  gameClients: GameClient[];
  addedInstitutions: string[];
  addedPositions: string[];
  addGameClient: (client: GameClient) => void;
  removeGameClient: (clientEmail: string) => void;
}

const GameClients: FC<GameClientsProps> = ({
  formRef,
  institutions,
  positions,
  participants,
  gameForms,
  gameClients,
  addedInstitutions,
  addedPositions,
  addGameClient,
  removeGameClient,
}) => {
  const [isAddClientButtonDisabled, setIsAddClientDisabled] = useState<boolean>(
    true,
  );

  const formConfig = useMemo(() => {
    const managersOptions = participants
      .filter(participant => {
        const isManagerAvailable =
          gameClients.findIndex(
            client => client.email === participant.email,
          ) === -1;
        return (
          participant.role === PARTICIPANT_ROLE.MANAGER &&
          isManagerAvailable &&
          !participant.global
        );
      })
      .map(manager => {
        return {
          id: manager.email,
          title: `${manager.name} - ${manager.email}`,
        };
      });

    const positionsOptions = [
      ...addedPositions.map(title => ({ id: title, title })),
      ...positions.map((position: any) => {
        return {
          id: position[POSITION_STRUCT.NAME],
          title: position[POSITION_STRUCT.NAME],
        };
      }),
    ].filter((position: any) => position[POSITION_STRUCT.NAME].length > 0);

    const institutionsOptions = [
      ...addedInstitutions.map(title => ({ id: title, title })),
      ...institutions.map((institution: any) => {
        return {
          id: institution[INSTITUTION_STRUCT.NAME],
          title: institution[INSTITUTION_STRUCT.NAME],
        };
      }),
    ].filter(
      (institution: any) => institution[INSTITUTION_STRUCT.NAME].length > 0,
    );

    const formsOptions = gameForms.map(form => {
      return {
        id: form.id,
        title: form.name,
      };
    });

    const config = [
      {
        id: CLIENT_STRUCT.EMAIL,
        title: 'Менеджер',
        type: FORM_TYPES.SELECT,
        options: managersOptions,
        placeholder: 'Выбрать менеджера',
        className: `${styles.selectBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: CLIENT_STRUCT.NAME,
        title: 'Врач',
        type: FORM_TYPES.INPUT,
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: CLIENT_STRUCT.POSITION,
        title: 'Должность',
        type: FORM_TYPES.SELECT,
        options: positionsOptions,
        placeholder: 'Выбрать должность',
        className: `${styles.selectBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: CLIENT_STRUCT.INSTITUTION,
        title: 'Учреждение',
        type: FORM_TYPES.SELECT,
        options: institutionsOptions,
        placeholder: 'Выбрать учреждение',
        className: `${styles.selectBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: CLIENT_STRUCT.TEAMS_URL,
        title: 'Ссылка на встречу',
        type: FORM_TYPES.INPUT,
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: CLIENT_STRUCT.RULES,
        title: 'Описание для врача',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        attrs: {
          rows: 5,
        },
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: CLIENT_STRUCT.DESCRIPTION,
        title: 'Описание для КАМа',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        attrs: {
          rows: 5,
        },
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: CLIENT_STRUCT.HAS_ACCESS_TO_REPORTS,
        title: 'Видимость отчетов',
        type: FORM_TYPES.CHECKBOX,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },

      {
        id: CLIENT_STRUCT.FORM,
        title: 'Форма',
        type: FORM_TYPES.SELECT,
        options: formsOptions,
        placeholder: 'Выбрать форму',
        className: `${styles.selectBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
    ];
    return config;
  }, [
    addedInstitutions,
    addedPositions,
    gameClients,
    gameForms,
    institutions,
    participants,
    positions,
  ]);

  const handleAddClient = useCallback(() => {
    const formData = formRef.current.getState();

    const selectedForm = gameForms.find(
      form => form.id === formData[CLIENT_STRUCT.FORM],
    );

    let tempClientData = {
      [CLIENT_STRUCT.EMAIL]: formData[CLIENT_STRUCT.EMAIL],
      [CLIENT_STRUCT.NAME]: formData[CLIENT_STRUCT.NAME],
      [CLIENT_STRUCT.POSITION]: formData[CLIENT_STRUCT.POSITION],
      [CLIENT_STRUCT.INSTITUTION]: formData[CLIENT_STRUCT.INSTITUTION],
      [CLIENT_STRUCT.TEAMS_URL]: formData[CLIENT_STRUCT.TEAMS_URL],
      [CLIENT_STRUCT.RULES]: formData[CLIENT_STRUCT.RULES],
      [CLIENT_STRUCT.DESCRIPTION]: formData[CLIENT_STRUCT.DESCRIPTION],
      [CLIENT_STRUCT.HAS_ACCESS_TO_REPORTS]:
        formData[CLIENT_STRUCT.HAS_ACCESS_TO_REPORTS],
      [CLIENT_STRUCT.SIXIB_QUESTIONS]: selectedForm?.SIXIB_questions,
      [CLIENT_STRUCT.SIXIB_LEGEND]: selectedForm?.SIXIB_legend,
    } as GameClient;
    addGameClient(tempClientData);

    formRef.current.setState([CLIENT_STRUCT.EMAIL], '');
    formRef.current.setState([CLIENT_STRUCT.NAME], '');
    formRef.current.setState([CLIENT_STRUCT.POSITION], '');
    formRef.current.setState([CLIENT_STRUCT.INSTITUTION], '');
    formRef.current.setState([CLIENT_STRUCT.TEAMS_URL], '');
    formRef.current.setState([CLIENT_STRUCT.RULES], '');
    formRef.current.setState([CLIENT_STRUCT.DESCRIPTION], '');
    formRef.current.setState([CLIENT_STRUCT.HAS_ACCESS_TO_REPORTS], false);
    formRef.current.setState([CLIENT_STRUCT.FORM], '');
  }, [addGameClient, formRef, gameForms]);

  const onChangeForm = (state: any) => {
    if (
      state[CLIENT_STRUCT.EMAIL].length === 0 ||
      state[CLIENT_STRUCT.NAME].length === 0 ||
      state[CLIENT_STRUCT.POSITION].length === 0 ||
      state[CLIENT_STRUCT.INSTITUTION].length === 0 ||
      state[CLIENT_STRUCT.TEAMS_URL].length === 0 ||
      state[CLIENT_STRUCT.RULES].length === 0 ||
      state[CLIENT_STRUCT.DESCRIPTION].length === 0 ||
      state[CLIENT_STRUCT.FORM].length === 0
    ) {
      setIsAddClientDisabled(true);
    } else {
      setIsAddClientDisabled(false);
    }
  };

  return (
    <>
      {/* @ts-ignore */}
      <Form ref={formRef} config={formConfig} onChange={onChangeForm} />
      <div
        className={`${styles.spaceBetweenRow} ${styles.addClientRowContainer}`}
      >
        <div className={styles.newClientTitle}>{'Добавить клиента'}</div>
        <Button
          key={'add_form_btn'}
          theme={THEMES.PRIMARY}
          onClick={handleAddClient}
          disabled={isAddClientButtonDisabled}
          style={undefined}
        >
          {'Добавить'}
        </Button>
      </div>
      <div className={styles.addedClientsContainer}>
        {gameClients.map(client => (
          <div
            key={`game_client_${client.email}`}
            className={`${styles.spaceBetweenRow} ${styles.clientItemContainer}`}
          >
            <div className={styles.addedClientTitle}>{client.name}</div>
            <div
              className={styles.removeClientLink}
              onClick={() => removeGameClient(client.email)}
            >
              {'Удалить'}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default branch(
  {
    institutions: institutionsSelector(),
    positions: positionsSelector(),
  },
  GameClients,
);
