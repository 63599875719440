//#region COMMON
export type SelectType = {
  id: string;
  title: string;
};
//#endregion

//#region CREATE GAME
export enum PARTICIPANT_ROLE {
  REPRESENTATIVE = 'representative',
  MANAGER = 'manager',
}
export type Participant = {
  name: string;
  email: string;
  connections: string[];
  role?: PARTICIPANT_ROLE;
  breaks?: string[][];
  global?: boolean;
};

export type GameForm = {
  id: number;
  name: string;
  SIXIB_questions: string;
  SIXIB_legend: string;
};

export type GameClient = {
  name: string;
  email: string;
  position: string;
  institution: string;
  teams_url: string;
  rules: string;
  description: string;
  has_report_tab: boolean;
  SIXIB_questions: string;
  SIXIB_legend: string;
};
//#endregion
