import React, { Component, createRef } from 'react';
import { branch } from 'baobab-react/higher-order';

import STRUCT, {
  GAME_STATUS as STATUS,
} from '../../../store/struct/entities/game';
import CALCULATED_GAME_STRUCT from '../../../store/struct/calculated/game';
import {
  finishGame,
  startGame,
  deleteGame,
  editGame,
  exportFile1,
  exportFile2,
  uploadFile,
  sendGamePins,
  autoLogout,
} from '../../../store/struct/entities/game/actions';

import Button, { THEMES } from '../../../components/button';
import Dialog from '../../../components/dialog';
import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import Form, { TYPES as FORM_TYPES } from '../../../components/form';
import { SUBTYPES } from '../../../components/form/input';
import Dropzone from '../../../components/dropzone';
import CreateGameDialog from './createGameDialog';

import styles from '../index.module.scss';

const HEADERS = ['Название игры', 'Статус', ''];
// const HEADERS = ['Название игры', 'Описание и цели игры', 'Статус', ''];

const actions = {
  START_GAME: 'Начать',
  EDIT_GAME: 'Редактировать',
  DELETE_GAME: 'Удалить',
  FINISH_GAME: 'Завершить',
  EXPORT_GAME1: 'Экспорт данных 1.0',
  EXPORT_GAME2: 'Экспорт данных 2.0',
  SEND_GAME_PINS: 'Выслать пины',
  ALL_AUTO_LOGOUT: 'Завершить сессии',
};

class Games extends Component {
  state = {
    showLoadDialog: false,
    showEditDialog: false,
    showAutoLogoutDialog: false,
    dataToAutoLogout: null,
    showAddGameDialog: false,
  };

  formConfig = [];
  formData = null;
  dropzone = createRef();
  form = createRef();

  loadGame = () => {
    this.setState({ showLoadDialog: true });
  };
  addGame = () => {
    this.setState({ showAddGameDialog: true });
  };

  editGame = data => {
    this.formConfig = this.getFormConfig(data);
    this.formData = data;
    this.setState({ showEditDialog: true });
  };

  exportGame1 = data => {
    this.props.dispatch(exportFile1, data[STRUCT.ID]);
  };

  exportGame2 = data => {
    this.props.dispatch(exportFile2, data[STRUCT.ID]);
  };

  confirmAutoLogout = data => {
    this.setState({ showAutoLogoutDialog: true, dataToAutoLogout: data });
  };

  autoLogout = () => {
    const data = this.state.dataToAutoLogout;

    this.props.dispatch(autoLogout, data[STRUCT.EXERCISE_ID]);
    this.closeDialog();
  };

  saveGame = () => {
    const data = { ...this.formData, ...this.form.current.getState() };

    this.props.dispatch(editGame, data);
    this.closeDialog();
  };

  onLoadGame = () => {
    const file = this.dropzone.current.getFile();

    if (file) {
      this.props.dispatch(uploadFile, file);
      this.closeDialog();
    }
  };

  closeDialog = () => {
    this.setState({
      showLoadDialog: false,
      showEditDialog: false,
      showAutoLogoutDialog: false,
      showAddGameDialog: false,
    });
  };

  handleAction = (data, action) => {
    switch (action) {
      case actions.START_GAME:
        this.props.dispatch(startGame, data);
        break;
      case actions.EDIT_GAME:
        this.editGame(data);
        break;
      case actions.DELETE_GAME:
        this.props.dispatch(deleteGame, data);
        break;
      case actions.FINISH_GAME:
        this.props.dispatch(finishGame, data);
        break;
      case actions.EXPORT_GAME1:
        this.exportGame1(data);
        break;
      case actions.EXPORT_GAME2:
        this.exportGame2(data);
        break;
      case actions.SEND_GAME_PINS:
        this.props.dispatch(sendGamePins, data);
        break;
      case actions.ALL_AUTO_LOGOUT:
        this.confirmAutoLogout(data);
        break;
      default:
        break;
    }
  };

  getOptions = data => {
    const status = data[STRUCT.STATUS_ID];
    return [
      {
        id: actions.START_GAME,
        title: actions.START_GAME,
        disabled: status !== STATUS.DEFAULT,
      },
      {
        id: actions.EDIT_GAME,
        title: actions.EDIT_GAME,
        disabled: status !== STATUS.DEFAULT,
      },
      {
        id: actions.DELETE_GAME,
        title: actions.DELETE_GAME,
        disabled: status !== STATUS.DEFAULT,
      },
      {
        id: actions.FINISH_GAME,
        title: actions.FINISH_GAME,
        disabled: status !== STATUS.PLAYING,
      },
      {
        id: actions.EXPORT_GAME1,
        title: actions.EXPORT_GAME1,
        disabled: status !== STATUS.FINISHED,
      },
      {
        id: actions.EXPORT_GAME2,
        title: actions.EXPORT_GAME2,
        disabled: status !== STATUS.FINISHED,
      },
      {
        id: actions.SEND_GAME_PINS,
        title: actions.SEND_GAME_PINS,
        disabled: false,
      },
      {
        id: actions.ALL_AUTO_LOGOUT,
        title: actions.ALL_AUTO_LOGOUT,
        disabled: status === STATUS.FINISHED,
      },
    ];
  };

  tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[STRUCT.NAME],
      min: true,
    },
    // {
    //   type: TABLE_TYPES.TEXT,
    //   getValue: data => data[STRUCT.DESC],
    // },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[CALCULATED_GAME_STRUCT.STATUS],
      getProps: data => {
        const status = data[STRUCT.STATUS_ID];

        return {
          className:
            status === STATUS.DEFAULT
              ? styles.greenText
              : status === STATUS.PLAYING
              ? styles.blueText
              : styles.fadedText,
        };
      },
    },
    {
      type: TABLE_TYPES.SELECT,
      onChange: this.handleAction,
      getOptions: this.getOptions,
      placeholder: 'Действие',
      min: true,
      resetAfterSelection: true,
    },
  ];

  getFormConfig = data => [
    {
      id: STRUCT.NAME,
      title: 'Название игры:',
      type: FORM_TYPES.INPUT,
      initialValue: data[STRUCT.NAME],
    },
    // {
    //   id: STRUCT.DESC,
    //   title: 'Цели и описание игры:',
    //   type: FORM_TYPES.INPUT,
    //   subtype: SUBTYPES.TEXTAREA,
    //   initialValue: data[STRUCT.DESC],
    //   attrs: {
    //     rows: 6,
    //   },
    // },
    {
      id: STRUCT.RULES,
      title: 'Возможности и ограничения Представителей:',
      type: FORM_TYPES.INPUT,
      subtype: SUBTYPES.TEXTAREA,
      initialValue: data[STRUCT.RULES],
      attrs: {
        rows: 6,
      },
    },
  ];

  render() {
    const { games } = this.props;
    const {
      showLoadDialog,
      showEditDialog,
      showAutoLogoutDialog,
      showAddGameDialog,
    } = this.state;

    return (
      <>
        <div className={` ${styles.block}`}>
          <Button onClick={this.loadGame} theme={THEMES.PRIMARY}>
            Загрузить игру
          </Button>
          <Button onClick={this.addGame} theme={THEMES.PRIMARY}>
            Новая игра
          </Button>
        </div>
        {/*<h2>Список игр</h2>*/}
        {games.length ? (
          <Table
            className={styles.tableWithControl}
            headers={HEADERS}
            config={this.tableConfig}
            data={games}
          />
        ) : (
          <div>Для того, чтобы начать игру, загрузите данные.</div>
        )}
        {showLoadDialog && (
          <Dialog
            title='Загрузка игры'
            submitTitle='Сохранить'
            onSubmit={this.onLoadGame}
            cancelTitle='Отменить'
            onCancel={this.closeDialog}
          >
            <Dropzone ref={this.dropzone} />
          </Dialog>
        )}
        {showEditDialog && (
          <Dialog
            title='Редактирование игры'
            submitTitle='Сохранить игру'
            onSubmit={this.saveGame}
            cancelTitle='Отменить'
            onCancel={this.closeDialog}
          >
            <Form ref={this.form} config={this.formConfig} />
          </Dialog>
        )}
        {showAutoLogoutDialog && (
          <Dialog
            title='Завершить сессии пользователей текущей игры'
            submitTitle='Завершить'
            onSubmit={this.autoLogout}
            cancelTitle='Отменить'
            onCancel={this.closeDialog}
          >
            <div>Данное действие завершит сессии всех игроков текущей игры</div>
          </Dialog>
        )}
        {showAddGameDialog && (
          <CreateGameDialog closeCreateDialog={this.closeDialog} />
        )}
      </>
    );
  }
}

export default branch({}, Games);
