import React, { FC, useCallback, useMemo, useState } from 'react';

import Input from '../../../../components/form/input';
import Button, { THEMES } from '../../../../components/button';
import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';

import styles from './index.module.scss';

import { Participant, PARTICIPANT_ROLE } from '../../../../types';

interface GameParticipantsProps {
  participants: Participant[];
  addParticipant: (value: Participant) => void;
  updateParticipant: (value: Participant) => void;
  deleteParticipant: (value: Participant) => void;
}

export const GameParticipants: FC<GameParticipantsProps> = ({
  participants,
  addParticipant,
  updateParticipant,
  deleteParticipant,
}) => {
  const [participantName, setParticipantName] = useState<string>('');
  const [participantEmail, setParticipantEmail] = useState<string>('');
  const [isEmailError, setIsEmailError] = useState<boolean>(false);
  const [isEmailNotUniqueError, setIsEmailNotUniqueError] = useState<boolean>(
    false,
  );
  const validateInput = (data: any) => {
    return data.length > 0 ? false : true;
  };

  const validateEmail = useCallback(
    (data: any) => {
      const isInputNotEmpty = validateInput(data);
      const isEmailValid = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        data.trim(),
      );
      setIsEmailNotUniqueError(false);
      setIsEmailError(isInputNotEmpty || isEmailValid);
      if (!isInputNotEmpty && !isEmailValid) {
        const currEmailIdx = participants.findIndex(
          participant => participant.email === data.toLowerCase().trim(),
        );

        setIsEmailError(currEmailIdx > -1);
        setIsEmailNotUniqueError(currEmailIdx > -1);
      }
    },
    [participants],
  );

  const handleOnChangeName = useCallback((value: string) => {
    setParticipantName(value);
  }, []);

  const handleOnChangeEmail = useCallback(
    (value: string) => {
      validateEmail(value);
      setParticipantEmail(value);
    },
    [validateEmail],
  );

  const handleAddParticipant = useCallback(() => {
    addParticipant({
      name: participantName,
      email: participantEmail,
      connections: [],
    });
    setParticipantName('');
    setParticipantEmail('');
  }, [addParticipant, participantEmail, participantName]);

  const participantsTableConfig = useMemo(() => {
    const config = [
      {
        type: TABLE_TYPES.TEXT,
        getValue: (data: Participant) => data.name,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: (data: Participant) => data.email,
      },
      {
        type: TABLE_TYPES.CHECKBOX,
        getValue: (data: Participant) => {
          if (data.role && data.role === PARTICIPANT_ROLE.REPRESENTATIVE) {
            return true;
          } else {
            return false;
          }
        },
        onChange: (data: Participant) => {
          updateParticipant({ ...data, role: PARTICIPANT_ROLE.REPRESENTATIVE });
        },
        getProps: (_: Participant) => ({
          className: styles.checkBoxContainer,
        }),
      },
      {
        type: TABLE_TYPES.CHECKBOX,
        getValue: (data: Participant) => {
          if (data.role && data.role === PARTICIPANT_ROLE.MANAGER) {
            return true;
          } else {
            return false;
          }
        },
        onChange: (data: Participant) => {
          updateParticipant({ ...data, role: PARTICIPANT_ROLE.MANAGER });
        },
        getProps: (_: Participant) => ({
          className: styles.checkBoxContainer,
        }),
      },
      {
        type: TABLE_TYPES.LINK,
        getValue: (_: Participant) => 'Удалить',
        onClick: (data: Participant) => deleteParticipant(data),
        getProps: (_: Participant) => ({
          className: styles.link,
        }),
        min: true,
      },
    ];
    return config;
  }, [deleteParticipant, updateParticipant]);

  return (
    <div className={styles.participantsContainer}>
      <div className={styles.participantsContent}>
        {participants.length > 0 && (
          <Table
            className={styles.participantsTable}
            headers={['ФИО', 'Email', 'КАМы', 'Менеджеры', '']}
            filters={[0, 1]}
            config={participantsTableConfig}
            data={participants}
          />
        )}
      </div>
      <div
        className={`${styles.spaceBetweenRow} ${styles.addPrticipantContainer}`}
      >
        <div className={styles.centerizedRow}>
          <div className={styles.title}>Новый участник</div>
          <Input
            attrs={{
              placeholder: 'Введите имя',
            }}
            value={participantName}
            onChange={handleOnChangeName}
            className={`${styles.infoInput} ${styles.marginLeft}`}
            subtype={undefined}
            readonly={undefined}
            disabled={undefined}
          />
          <div>
            <Input
              attrs={{
                placeholder: 'Введите email',
              }}
              value={participantEmail}
              onChange={handleOnChangeEmail}
              className={`${styles.infoInput} ${styles.marginLeft} ${
                isEmailError || isEmailNotUniqueError
                  ? styles.errorInputContainer
                  : ''
              }`}
              subtype={undefined}
              readonly={undefined}
              disabled={undefined}
            />
            {isEmailNotUniqueError && (
              <div className={`${styles.errorText} ${styles.marginLeft}`}>
                {'Участник с таким email уже добавлен'}
              </div>
            )}
          </div>
        </div>
        <Button
          key={'add_participant_btn'}
          theme={THEMES.PRIMARY}
          onClick={handleAddParticipant}
          disabled={!participantName || !participantEmail || isEmailError}
          style={undefined}
        >
          {'Добавить'}
        </Button>
      </div>
    </div>
  );
};

export default GameParticipants;
