import React, { FC } from 'react';
import DateTimePicker from 'react-datetime-picker';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

import styles from './index.module.scss';

interface DatePeriodProps {
  attrs: any;
  value: any;
  onChange: any;
  className: any;
  disabled: boolean;
}

const DatePeriod: FC<DatePeriodProps> = ({
  attrs,
  value,
  onChange,
  className,
  disabled,
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <DateTimePicker
        className={styles.dateInput}
        onChange={startValue => onChange([startValue, value[1]])}
        value={value[0]}
        disabled={disabled}
        calendarIcon={null}
        disableClock={true}
        format='dd.MM.yyyy'
        shouldOpenWidgets={({ reason, widget }) => {
          return widget === 'calendar';
        }}
        {...attrs.startDate}
      />
      <div className={styles.separator}>{'-'}</div>
      <DateTimePicker
        className={styles.dateInput}
        onChange={endValue => onChange([value[0], endValue])}
        value={value[1]}
        disabled={disabled}
        calendarIcon={null}
        disableClock={true}
        format='dd.MM.yyyy'
        shouldOpenWidgets={({ reason, widget }) => {
          return widget === 'calendar';
        }}
        {...attrs.endDate}
      />
    </div>
  );
};

export default DatePeriod;
