import find from 'lodash.find';

export const getById = (array, id) => {
  if (!array || !array.length) {
    return null;
  }

  return find(array, { id }) || null;
};

export const toDecimalPlaces = (value, decimalPlaces) => {
  const k = Math.pow(10, decimalPlaces);

  return Math.round(value * k) / k;
};

export const formatDate = date => {
  return (
    date.getFullYear() +
    '-' +
    String(date.getMonth() + 1).padStart(2, '0') +
    '-' +
    String(date.getDate()).padStart(2, '0')
  );
};

export const formatDateToShow = dateString => {
  const [year, month, day] = dateString.split('-');
  return `${day}.${month}.${year}`;
};
