import React, {
  useMemo,
  useState,
  useCallback,
  FC,
  createRef,
  useEffect,
} from 'react';
// @ts-ignore
import { branch } from 'baobab-react/higher-order';

import _ from 'lodash';

import { uploadGameForm } from '../../../store/struct/entities/game/actions';
import { execAfterUserConfirmation } from '../../../store/struct/app/actions';

import { THEMES } from '../../../components/button';
import { formatDate, formatDateToShow } from '../../../utils';

import GameForms from './forms';
import GameGeneral from './general';
import GameClients from './clients';
import GameParticipants from './participants';
import GameAvailability from './availability';
import Dialog from '../../../components/dialog';
import Checkbox from '../../../components/checkbox';
import Select from '../../../components/form/select';
import Form, { TYPES as FORM_TYPES } from '../../../components/form';

import GAME_STRUCT from '../../../store/struct/entities/game';

import styles from './index.module.scss';

import {
  GameClient,
  GameForm,
  Participant,
  PARTICIPANT_ROLE,
  SelectType,
} from '../../../types';

const BTNS = {
  BACK: 'back',
  NEXT: 'next',
  SAVE: 'save',
  CANCEL: 'cancel',
};

const STEPS = {
  GENERAL: 0,
  PARTICIPANTS: 1,
  AVAILABILITY: 2,
  FORMS: 3,
  CLIENTS: 4,
};

const BACK_BTN_TITLES = {
  [STEPS.PARTICIPANTS]: 'Общие',
  [STEPS.AVAILABILITY]: 'Участники',
  [STEPS.FORMS]: 'Доступность',
  [STEPS.CLIENTS]: 'Формы',
};

const NEXT_BTN_TITLES = {
  [STEPS.GENERAL]: 'Участники',
  [STEPS.PARTICIPANTS]: 'Доступность',
  [STEPS.AVAILABILITY]: 'Формы',
  [STEPS.FORMS]: 'Клиенты',
};

const DIALOG_TITLES = {
  [STEPS.GENERAL]: 'Новая игра - Общие',
  [STEPS.PARTICIPANTS]: 'Новая игра - Участники и роли',
  [STEPS.AVAILABILITY]: 'Новая игра - Доступность',
  [STEPS.FORMS]: 'Новая игра - Формы',
  [STEPS.CLIENTS]: 'Новая игра - Клиенты',
};

interface CreateGameDialogProps {
  closeCreateDialog: () => void;
  dispatch: any;
}

interface StepProps {
  step: number;
  current: number;
  children: any;
}

const Step: FC<StepProps> = ({ step, current, children }) => (
  <div
    className={
      step === current
        ? step === STEPS.PARTICIPANTS
          ? styles.fullHeight
          : ''
        : styles.hidden
    }
  >
    {children}
  </div>
);

const CreateGameDialog: FC<CreateGameDialogProps> = ({
  closeCreateDialog,
  dispatch,
}) => {
  const [step, setStep] = useState<number>(0);

  const stepOrders = useMemo(() => {
    const arr = [
      STEPS.GENERAL,
      STEPS.PARTICIPANTS,
      STEPS.AVAILABILITY,
      STEPS.FORMS,
      STEPS.CLIENTS,
    ];
    return arr;
  }, []);

  const gameGeneralFormRef = createRef<any>();
  const participantBreaksFormRef = createRef<any>();
  const gameFormsFormRef = createRef<any>();
  const gameClientsFormRef = createRef<any>();

  const [errorValidationDays, setErrorValidationDays] = useState<boolean>(
    false,
  );
  const [errorValidationHours, setErrorValidationHours] = useState<boolean>(
    false,
  );
  const [errorValidationBreaks, setErrorValidationBreaks] = useState<boolean>(
    false,
  );
  const [errorValidationBreaksText, setErrorValidationBreaksText] = useState<
    string
  >('');
  const [
    errorValidationBreaksForDay,
    setErrorValidationBreaksForDay,
  ] = useState<boolean>(false);
  const [
    errorValidationBreaksForDayText,
    setErrorValidationBreaksForDayText,
  ] = useState<string>('');
  const [
    errorValidationMeetingLength,
    setErrorValidationMeetingLength,
  ] = useState<boolean>(false);
  const [
    errorValidationMeetingBreak,
    setErrorValidationMeetingBreak,
  ] = useState<boolean>(false);
  const [
    errorValidationMeetingConfirmationTime,
    setErrorValidationMeetingConfirmationTime,
  ] = useState<boolean>(false);

  const [participants, setParticipants] = useState<Participant[]>([]);

  const [generalFormData, setGeneralFormData] = useState<any>();
  const [gameDaysArray, setGameDaysArray] = useState<string[]>([]);
  const [gameHours, setGameHours] = useState<string>('');
  const [gameInstitutions, setGameInstitutions] = useState<string[]>([]);
  const [gamePositions, setGamePositions] = useState<string[]>([]);

  const [gameForms, setGameForms] = useState<GameForm[]>([]);
  const [gameClients, setGameClients] = useState<GameClient[]>([]);

  const [
    currentParticipant,
    setCurrentParticipant,
  ] = useState<Participant | null>(null);
  const [connectionEmailToAdd, setConnectionEmailToAdd] = useState<string>('');
  const [connectionsEmails, setConnectionsEmails] = useState<string[]>([]);

  const [isConnectionsDialogVisible, setIsConnectionsDialogVisible] = useState<
    boolean
  >(false);
  const [
    isParticipantBreaksDialogVisible,
    setIsParticipantBreaksDialogVisible,
  ] = useState<boolean>(false);
  const [isAllConnectionsSelected, setIsAllConnectionsSelected] = useState<
    boolean
  >(false);

  const toggleIsConnectionsDialogVisible = useCallback(() => {
    setIsConnectionsDialogVisible(!isConnectionsDialogVisible);
  }, [isConnectionsDialogVisible]);
  const handleCloseConnectionsDialog = useCallback(() => {
    setCurrentParticipant(null);
    setConnectionsEmails([]);
    toggleIsConnectionsDialogVisible();
    setIsAllConnectionsSelected(false);
  }, [toggleIsConnectionsDialogVisible]);

  const toggleIsParticipantBreaksDialogVisible = useCallback(() => {
    setIsParticipantBreaksDialogVisible(!isParticipantBreaksDialogVisible);
  }, [isParticipantBreaksDialogVisible]);
  const handleCloseParticipantBreaksDialog = useCallback(() => {
    setCurrentParticipant(null);
    toggleIsParticipantBreaksDialogVisible();
    setErrorValidationBreaksForDay(false);
    setErrorValidationBreaksForDayText('');
  }, [toggleIsParticipantBreaksDialogVisible]);

  useEffect(() => {
    if (
      currentParticipant &&
      currentParticipant.role === PARTICIPANT_ROLE.MANAGER
    ) {
      const availableConnectionsForManager = participants.filter(
        participant => participant.role === PARTICIPANT_ROLE.REPRESENTATIVE,
      );
      if (connectionsEmails.length === availableConnectionsForManager.length) {
        setIsAllConnectionsSelected(true);
      } else {
        setIsAllConnectionsSelected(false);
      }
    }
  }, [connectionsEmails.length, currentParticipant, participants]);

  useEffect(() => {
    if (generalFormData) {
      if (generalFormData[GAME_STRUCT.DAYS]) {
        const dates = [...generalFormData[GAME_STRUCT.DAYS]];
        const hasNull = dates.some(item => item === null || item === undefined);

        if (dates.length > 1 && !hasNull) {
          const start = new Date(dates[0]);
          const end = new Date(dates[dates.length - 1]);

          const result = [];

          while (formatDate(start) <= formatDate(end)) {
            result.push(formatDate(start));
            start.setDate(start.getDate() + 1);
          }
          setGameDaysArray(result);
        }
      }
      if (
        generalFormData[GAME_STRUCT.HOURS] &&
        generalFormData[GAME_STRUCT.HOURS].length > 1
      ) {
        const dateRange = generalFormData[GAME_STRUCT.HOURS];
        const formatTime = (isoString: string) => {
          const date = new Date(isoString);
          return date.toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
          });
        };
        setGameHours(`${formatTime(dateRange[0])}-${formatTime(dateRange[1])}`);
      }
      if (generalFormData[GAME_STRUCT.INSTITUTIONS]) {
        const formattedInstitutions = generalFormData[GAME_STRUCT.INSTITUTIONS]
          .split('\n')
          .map((line: string) =>
            line
              .trim()
              .replace(/^"|"$/, '')
              .replace(/",?$/, ''),
          )
          .filter((line: string) => line.length > 0);
        setGameInstitutions(formattedInstitutions);
      }
      if (generalFormData[GAME_STRUCT.POSITIONS]) {
        const formattedPositions = generalFormData[GAME_STRUCT.POSITIONS]
          .split('\n')
          .map((line: string) =>
            line
              .trim()
              .replace(/^"|"$/, '')
              .replace(/",?$/, ''),
          )
          .filter((line: string) => line.length > 0);
        setGamePositions(formattedPositions);
      }
    }
  }, [generalFormData]);

  const validateDays = (value: Date[]) => {
    if (value.length > 1) {
      const hasNull = value.some(item => item === null);
      if (hasNull) {
        setErrorValidationDays(true);
      } else {
        const hasUndefined = value.some(item => item === undefined);
        if (!hasUndefined) {
          if (value[1] > value[0]) {
            setErrorValidationDays(false);
          } else {
            setErrorValidationDays(true);
          }
        } else {
          setErrorValidationDays(false);
        }
      }
    } else {
      setErrorValidationDays(true);
    }
  };
  const validateHours = (value: Date[]) => {
    if (value.length > 1) {
      const hasNull = value.some(item => item === null);
      if (hasNull) {
        setErrorValidationHours(true);
      } else {
        if (value[1] > value[0]) {
          setErrorValidationHours(false);
        } else {
          setErrorValidationHours(true);
        }
      }
    } else {
      setErrorValidationHours(true);
    }
  };
  const validateBreaks = (input: string, day?: string) => {
    const values = input.split(',').map(v => v.trim());
    if (
      !Array.isArray(values) ||
      values.length === 0 ||
      (values.length > 0 && values[0] === '')
    ) {
      if (day) {
        setErrorValidationBreaksForDay(true);
        setErrorValidationBreaksForDayText('Список не должен быть пустым.');
      }
      setErrorValidationBreaks(true);
      setErrorValidationBreaksText('Список не должен быть пустым.');
      return;
    }

    const timeRegex = /^([01]?\d|2[0-3]):([0-5]\d)-([01]?\d|2[0-3]):([0-5]\d)$/;
    const uniqueValues = new Set(values);

    if (uniqueValues.size !== values.length) {
      if (day) {
        setErrorValidationBreaksForDay(true);
        setErrorValidationBreaksForDayText(
          `Значения перерывов для ${day} не должны повторяться.`,
        );
      }
      setErrorValidationBreaks(true);
      setErrorValidationBreaksText('Значения не должны повторяться.');
      return;
    }

    for (const value of values) {
      const match = value.match(timeRegex);
      if (!match) {
        if (day) {
          setErrorValidationBreaksForDay(true);
        }
        setErrorValidationBreaks(true);
        setErrorValidationBreaksText(`Неверный формат времени: ${value}`);
        return;
      }

      const [, h1, m1, h2, m2] = match.map(Number);
      if (h1 * 60 + m1 >= h2 * 60 + m2) {
        if (day) {
          setErrorValidationBreaksForDay(true);
          setErrorValidationBreaksForDayText(
            `Некорректный диапазон времени перерывов для ${day}: ${value}`,
          );
        }
        setErrorValidationBreaks(true);
        setErrorValidationBreaksText(`Некорректный диапазон времени: ${value}`);
        return;
      }
    }

    setErrorValidationBreaks(false);
    setErrorValidationBreaksText('');
    setErrorValidationBreaksForDay(false);
    setErrorValidationBreaksForDayText('');
  };

  const validateMeetingLength = (value: number) => {
    if (value > 0) {
      setErrorValidationMeetingLength(false);
    } else {
      setErrorValidationMeetingLength(true);
    }
  };
  const validateMeetingBreak = (value: number) => {
    if (value > 0) {
      setErrorValidationMeetingBreak(false);
    } else {
      setErrorValidationMeetingBreak(true);
    }
  };
  const validateMeetingConfirmationTime = (value: number) => {
    if (value > 0) {
      setErrorValidationMeetingConfirmationTime(false);
    } else {
      setErrorValidationMeetingConfirmationTime(true);
    }
  };

  const onSaveGame = useCallback(() => {
    const managers = participants
      .filter(participant => participant.role === PARTICIPANT_ROLE.MANAGER)
      .map(participant => {
        return {
          name: participant.name,
          email: participant.email,
          representatives: participant.connections,
          breaks: participant.breaks,
          global: participant.global,
        };
      });
    const representatives = participants
      .filter(
        participant => participant.role === PARTICIPANT_ROLE.REPRESENTATIVE,
      )
      .map(participant => {
        return {
          name: participant.name,
          email: participant.email,
          clients: participant.connections,
        };
      });

    const data = {
      game: {
        desc: generalFormData[GAME_STRUCT.DESC],
        name: generalFormData[GAME_STRUCT.NAME],
        rules: generalFormData[GAME_STRUCT.RULES],
        hours: gameHours,
        breaks: [generalFormData[GAME_STRUCT.BREAKS]],
        days: gameDaysArray,
        meetingLength: Number(generalFormData[GAME_STRUCT.MEETING_LENGTH]),
        meetingBreak: Number(generalFormData[GAME_STRUCT.MEETING_BREAK]),
        meetingConfirmationTime: Number(
          generalFormData[GAME_STRUCT.MEETING_CONFIRMATION_TIME],
        ),
      },
      positions: gamePositions,
      institutions: gameInstitutions,
      managers,
      representatives,
      clients: gameClients,
    };

    dispatch(uploadGameForm, data, closeCreateDialog);
  }, [
    closeCreateDialog,
    dispatch,
    gameClients,
    gameDaysArray,
    gameHours,
    gameInstitutions,
    gamePositions,
    generalFormData,
    participants,
  ]);

  const isNextStepDisabled = useCallback(() => {
    switch (step) {
      case STEPS.GENERAL:
        return generalFormData
          ? _.isEmpty(generalFormData[GAME_STRUCT.DESC]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.NAME]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.RULES]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.BREAKS]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.DAYS]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.HOURS]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.MEETING_BREAK]) ||
              _.isEmpty(
                generalFormData[GAME_STRUCT.MEETING_CONFIRMATION_TIME],
              ) ||
              _.isEmpty(generalFormData[GAME_STRUCT.MEETING_LENGTH]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.INSTITUTIONS]) ||
              _.isEmpty(generalFormData[GAME_STRUCT.POSITIONS]) ||
              errorValidationBreaks ||
              errorValidationDays ||
              errorValidationHours ||
              errorValidationMeetingBreak ||
              errorValidationMeetingConfirmationTime ||
              errorValidationMeetingLength
          : true;
      case STEPS.PARTICIPANTS:
        const allValidRoles = participants.every(p => p.role !== undefined);
        const hasManager = participants.some(
          p => p.role === PARTICIPANT_ROLE.MANAGER,
        );
        const hasRepresentative = participants.some(
          p => p.role === PARTICIPANT_ROLE.REPRESENTATIVE,
        );
        return participants.length > 0
          ? !(allValidRoles && hasManager && hasRepresentative)
          : true;
      case STEPS.AVAILABILITY:
        return false;
      case STEPS.FORMS:
        return gameForms.length === 0;
    }
  }, [
    errorValidationBreaks,
    errorValidationDays,
    errorValidationHours,
    errorValidationMeetingBreak,
    errorValidationMeetingConfirmationTime,
    errorValidationMeetingLength,
    generalFormData,
    step,
    participants,
    gameForms,
  ]);

  const handleClick = useCallback(
    (id: string) => {
      if (id === BTNS.BACK) {
        setStep(step - 1);
      }
      if (id === BTNS.NEXT) {
        setStep(step + 1);
      }
      if (id === BTNS.SAVE) {
        onSaveGame();
      }
    },
    [onSaveGame, step],
  );

  const buttonsConfig = useMemo(() => {
    let btns: any = [];

    if (step > 0) {
      btns.push({
        id: BTNS.BACK,
        title: BACK_BTN_TITLES[step],
        theme: THEMES.SECONDARY,
      });
    }
    if (step < stepOrders.length - 1) {
      btns.push({
        id: BTNS.NEXT,
        title: NEXT_BTN_TITLES[step],
        theme: THEMES.SECONDARY,
        disabled: isNextStepDisabled(),
      });
    }
    if (step === stepOrders.length - 1) {
      btns.push({
        id: BTNS.SAVE,
        title: 'Сохранить',
        disabled: gameClients.length === 0,
      });
    }
    return btns;
  }, [gameClients.length, isNextStepDisabled, step, stepOrders.length]);

  const handleGeneralFormData = useCallback((data: any) => {
    setGeneralFormData(data);
  }, []);

  const addParticipant = useCallback(
    (participant: Participant) => {
      let tempParticipants = [...participants];
      tempParticipants.push(participant);
      setParticipants(tempParticipants);
    },
    [participants],
  );

  const updateParticipant = useCallback(
    (participant: Participant) => {
      let tempParticipants = [...participants];
      const index = tempParticipants.findIndex(
        obj => obj.email === participant.email,
      );
      if (index !== -1) {
        tempParticipants[index] = Object.assign(
          {},
          tempParticipants[index],
          participant,
        );
      }
      setParticipants(tempParticipants);
    },
    [participants],
  );

  const deleteParticipant = useCallback(
    (participant: Participant) => {
      let tempParticipants = [...participants];
      const index = tempParticipants.findIndex(
        obj => obj.email === participant.email,
      );
      tempParticipants = [
        ...tempParticipants.slice(0, index),
        ...tempParticipants.slice(index + 1),
      ];
      setParticipants(tempParticipants);
    },
    [participants],
  );

  const handleChangeConnectionEmailToAdd = useCallback(
    (emailOption: string) => {
      setConnectionEmailToAdd(emailOption);
    },
    [],
  );

  const connectionEmailsOptions: SelectType[] = useMemo(() => {
    return participants
      .filter(participant => {
        if (!currentParticipant) return false;

        const isRoleMatching =
          currentParticipant.role === PARTICIPANT_ROLE.MANAGER
            ? participant.role === PARTICIPANT_ROLE.REPRESENTATIVE
            : participant.role === PARTICIPANT_ROLE.MANAGER;

        const isNotConnected = !connectionsEmails.includes(participant.email);

        return isRoleMatching && isNotConnected;
      })
      .map(participant => {
        return { id: participant.email, title: participant.email };
      });
  }, [connectionsEmails, currentParticipant, participants]);

  const handleSetCurrentParticipant = useCallback(
    (data: Participant | null) => {
      setCurrentParticipant(data);
      if (data) {
        setConnectionsEmails(data.connections);
      }
    },
    [],
  );

  const handleSaveConnections = useCallback(() => {
    if (currentParticipant) {
      updateParticipant({
        ...currentParticipant,
        connections: connectionsEmails,
        global: isAllConnectionsSelected,
      });
      handleCloseConnectionsDialog();
    }
  }, [
    connectionsEmails,
    currentParticipant,
    handleCloseConnectionsDialog,
    isAllConnectionsSelected,
    updateParticipant,
  ]);

  const handleAddConnection = useCallback(
    (email: string) => {
      if (email) {
        let tempConnections = [...connectionsEmails];
        tempConnections.push(email);
        setConnectionsEmails(tempConnections);
      }
      setConnectionEmailToAdd('');
    },
    [connectionsEmails],
  );

  const handleRemoveConnection = useCallback(
    (email: string) => {
      let tempConnections = [...connectionsEmails];
      const index = tempConnections.findIndex(
        connectionEmail => connectionEmail === email,
      );
      tempConnections = [
        ...tempConnections.slice(0, index),
        ...tempConnections.slice(index + 1),
      ];
      setConnectionsEmails(tempConnections);
      setConnectionEmailToAdd('');
    },
    [connectionsEmails],
  );

  const handleChangeSelectAllConnections = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setConnectionsEmails([
          ...connectionsEmails,
          ...connectionEmailsOptions.map(connection => connection.id),
        ]);
        setConnectionEmailToAdd('');
        setIsAllConnectionsSelected(isChecked);
      } else {
        setConnectionsEmails([]);
        setConnectionEmailToAdd('');
        setIsAllConnectionsSelected(isChecked);
      }
    },
    [connectionEmailsOptions, connectionsEmails],
  );

  const participantBreaksFormConfig = useMemo(() => {
    const config = gameDaysArray.map(day => {
      const formattedDay = formatDateToShow(day);
      return {
        id: `participant_breaks_day_${formattedDay}`,
        title: formattedDay,
        type: FORM_TYPES.INPUT,
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.centerizedRow,
        formComponentWrapperClassName: styles.maxInputWidth,
        validationFunc: (input: string) => validateBreaks(input, formattedDay),
      };
    });
    return config;
  }, [gameDaysArray]);

  const handleSaveParticipantBreaks = useCallback(() => {
    const dataResults = participantBreaksFormRef.current.getState();
    const result = Object.values(dataResults).reduce(
      (acc: any, current: any) => {
        const trim = current
          .split(',')
          .reduce((accTrim: any, parameter: any) => {
            const res = parameter.trim();
            if (res.length > 0) {
              accTrim.push(res);
            }
            return accTrim;
          }, []);
        acc.push(trim);
        return acc;
      },
      [],
    ) as string[][];

    if (currentParticipant) {
      updateParticipant({
        ...currentParticipant,
        breaks: result,
      });
    }
    handleCloseParticipantBreaksDialog();
  }, [
    currentParticipant,
    handleCloseParticipantBreaksDialog,
    participantBreaksFormRef,
    updateParticipant,
  ]);

  const addGameForm = useCallback(
    (form: GameForm) => {
      let tempGameForms = [...gameForms];
      tempGameForms.push(form);
      setGameForms(tempGameForms);
    },
    [gameForms],
  );

  const removeGameForm = useCallback(
    (formId: number) => {
      let tempGameForms = [...gameForms];
      const index = tempGameForms.findIndex(form => form.id === formId);
      tempGameForms = [
        ...tempGameForms.slice(0, index),
        ...tempGameForms.slice(index + 1),
      ];
      setGameForms(tempGameForms);
    },
    [gameForms],
  );

  const addGameClient = useCallback(
    (client: GameClient) => {
      let tempGameClients = [...gameClients];
      tempGameClients.push(client);
      setGameClients(tempGameClients);
    },
    [gameClients],
  );

  const removeGameClient = useCallback(
    (clientEmail: string) => {
      let tempGameClients = [...gameClients];
      const index = tempGameClients.findIndex(
        client => client.email === clientEmail,
      );
      tempGameClients = [
        ...tempGameClients.slice(0, index),
        ...tempGameClients.slice(index + 1),
      ];
      setGameClients(tempGameClients);
    },
    [gameClients],
  );

  const handleCloseCreateDialog = useCallback(() => {
    dispatch(execAfterUserConfirmation, () => closeCreateDialog());
  }, [closeCreateDialog, dispatch]);

  return (
    <>
      {/* @ts-ignore */}
      <Dialog
        title={DIALOG_TITLES[step]}
        onCancel={
          {
            0: handleCloseCreateDialog,
            1: handleCloseCreateDialog,
            2: handleCloseCreateDialog,
            3: handleCloseCreateDialog,
            4: handleCloseCreateDialog,
          }[step]
        }
        cancelTitle={'Отмена'}
        buttons={buttonsConfig}
        onClick={handleClick}
        bodyClassName={styles.createGameDialogBody}
        titleClassName={styles.dialogTitle}
        fullScreen
      >
        <Step step={STEPS.GENERAL} current={stepOrders[step]}>
          <GameGeneral
            formRef={gameGeneralFormRef}
            errorValidationDays={errorValidationDays}
            errorValidationHours={errorValidationHours}
            errorValidationBreaks={errorValidationBreaks}
            errorValidationBreaksText={errorValidationBreaksText}
            validateDays={validateDays}
            validateHours={validateHours}
            validateBreaks={validateBreaks}
            handleGeneralFormData={handleGeneralFormData}
            errorValidationMeetingLength={errorValidationMeetingLength}
            errorValidationMeetingBreak={errorValidationMeetingBreak}
            errorValidationMeetingConfirmationTime={
              errorValidationMeetingConfirmationTime
            }
            validateMeetingLength={validateMeetingLength}
            validateMeetingBreak={validateMeetingBreak}
            validateMeetingConfirmationTime={validateMeetingConfirmationTime}
          />
        </Step>
        <Step step={STEPS.PARTICIPANTS} current={stepOrders[step]}>
          <GameParticipants
            participants={participants}
            addParticipant={addParticipant}
            updateParticipant={updateParticipant}
            deleteParticipant={deleteParticipant}
          />
        </Step>
        <Step step={STEPS.AVAILABILITY} current={stepOrders[step]}>
          <GameAvailability
            participants={participants}
            toggleIsConnectionsDialogVisible={toggleIsConnectionsDialogVisible}
            toggleIsBreaksDialogVisible={toggleIsParticipantBreaksDialogVisible}
            setCurrentParticipant={handleSetCurrentParticipant}
          />
        </Step>
        <Step step={STEPS.FORMS} current={stepOrders[step]}>
          <GameForms
            formRef={gameFormsFormRef}
            gameForms={gameForms}
            addGameForm={addGameForm}
            removeGameForm={removeGameForm}
          />
        </Step>
        <Step step={STEPS.CLIENTS} current={stepOrders[step]}>
          <GameClients
            formRef={gameClientsFormRef}
            participants={participants}
            gameForms={gameForms}
            gameClients={gameClients}
            addGameClient={addGameClient}
            removeGameClient={removeGameClient}
            addedInstitutions={gameInstitutions}
            addedPositions={gamePositions}
          />
        </Step>
      </Dialog>
      {isConnectionsDialogVisible && currentParticipant && (
        // @ts-ignore
        <Dialog
          title={'Настройка связей'}
          onCancel={handleCloseConnectionsDialog}
          cancelTitle={'Отмена'}
          buttons={[
            {
              id: 'save_connections',
              title: 'Сохранить связи',
              theme: THEMES.PRIMARY,
            },
          ]}
          onClick={handleSaveConnections}
          bodyClassName={
            connectionsEmails.length > 0 ? styles.availabilityDialog : null
          }
        >
          <div>
            {currentParticipant.role === PARTICIPANT_ROLE.MANAGER && (
              <div
                className={`${styles.centerizedRow} ${styles.selectAllConnectionContainer}`}
              >
                <Checkbox
                  id={`select_all_checkbox`}
                  onChange={checked => {
                    handleChangeSelectAllConnections(checked);
                  }}
                  checked={isAllConnectionsSelected}
                  className={styles.selectAllCheckbox}
                />
                <div className={styles.selectTitle}>{'Выбрать всех'}</div>
              </div>
            )}
            <div className={styles.spaceBetweenRow}>
              <div className={styles.centerizedRow}>
                <div className={styles.selectTitle}>
                  {currentParticipant.role === PARTICIPANT_ROLE.MANAGER
                    ? 'Выберите КАМа'
                    : 'Выберите менеджера'}
                </div>
                <Select
                  onChange={handleChangeConnectionEmailToAdd}
                  options={connectionEmailsOptions}
                  value={connectionEmailToAdd}
                  className={styles.selectMargin}
                  placeholder={
                    currentParticipant.role === PARTICIPANT_ROLE.MANAGER
                      ? 'КАМ'
                      : 'Менеджер'
                  }
                  selectablePlaceholder
                  onReset={() => setConnectionEmailToAdd('')}
                  attrs={undefined}
                  disabled={undefined}
                  resetAfterSelection={undefined}
                  resetTrigger={undefined}
                  showZeroValue={undefined}
                />
              </div>
              <div
                className={styles.addConnectionLink}
                onClick={() => handleAddConnection(connectionEmailToAdd)}
              >
                {'Добавить'}
              </div>
            </div>
            <div className={styles.connectionsContainer}>
              <div className={styles.selectTitle}>{'Связи:'}</div>
              <div className={styles.fullWidth}>
                {connectionsEmails.length > 0 ? (
                  connectionsEmails.map((email, index) => (
                    <div
                      key={`connection_email_${index}`}
                      className={`${styles.spaceBetweenRow} ${styles.connectionItemContainer}`}
                    >
                      <div className={styles.connectionTitle}>{email}</div>
                      <div
                        className={styles.removeConnectionLink}
                        onClick={() => handleRemoveConnection(email)}
                      >
                        {'Удалить'}
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    className={styles.connectionTitle}
                  >{`Связи для участника: ${currentParticipant.name} пока не настроены.\nПожалуйста, выберите одно или несколько значений из списка`}</div>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {isParticipantBreaksDialogVisible && currentParticipant && (
        // @ts-ignore
        <Dialog
          title={'Настройка перерывов'}
          onCancel={handleCloseParticipantBreaksDialog}
          cancelTitle={'Отмена'}
          buttons={[
            {
              id: 'save_participant_breaks',
              title: 'Сохранить перерывы',
              theme: THEMES.PRIMARY,
              disabled: errorValidationBreaksForDay,
            },
          ]}
          onClick={handleSaveParticipantBreaks}
        >
          <>
            <Form
              ref={participantBreaksFormRef}
              // @ts-ignore
              config={participantBreaksFormConfig}
            />
            {errorValidationBreaksForDay && (
              <div className={styles.errorValidationText}>
                {errorValidationBreaksForDayText}
              </div>
            )}
          </>
        </Dialog>
      )}
    </>
  );
};

export default branch({}, CreateGameDialog);
