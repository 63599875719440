import React, { FC, useCallback, useMemo, useState } from 'react';

import { SUBTYPES } from '../../../../components/form/input';
import Button, { THEMES } from '../../../../components/button';
import Form, { TYPES as FORM_TYPES } from '../../../../components/form';

import styles from './index.module.scss';

import { GameForm } from '../../../../types';

export const GAME_FORM_STRUCT = {
  NAME: 'name',
  QUESTIONS: 'SIXIB_questions',
  LEGEND: 'SIXIB_legend',
};

interface GameFormsProps {
  formRef: React.RefObject<any>;
  gameForms: GameForm[];
  addGameForm: (form: GameForm) => void;
  removeGameForm: (formId: number) => void;
}

const GameForms: FC<GameFormsProps> = ({
  formRef,
  gameForms,
  addGameForm,
  removeGameForm,
}) => {
  const [isAddFormButtonDisabled, setIsAddFormDisabled] = useState<boolean>(
    true,
  );

  const formConfig = useMemo(() => {
    const config = [
      {
        id: GAME_FORM_STRUCT.NAME,
        title: 'Название формы',
        type: FORM_TYPES.INPUT,
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: GAME_FORM_STRUCT.QUESTIONS,
        title: 'Вопросы',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        attrs: {
          rows: 5,
        },
        titleUnder: 'Каждый вопрос вводите с новой строки',
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: GAME_FORM_STRUCT.LEGEND,
        title: 'Оценка и баллы',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        attrs: {
          rows: 5,
        },
        titleUnder: 'Каждую оценку вводите с новой строки',
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
    ];
    return config;
  }, []);

  const onChangeForm = (state: any) => {
    if (
      state[GAME_FORM_STRUCT.NAME].length === 0 ||
      state[GAME_FORM_STRUCT.QUESTIONS].length === 0 ||
      state[GAME_FORM_STRUCT.LEGEND].length === 0
    ) {
      setIsAddFormDisabled(true);
    } else {
      setIsAddFormDisabled(false);
    }
  };

  const handleAddForm = useCallback(() => {
    const formData = formRef.current.getState();
    const formattedQuestions = [
      {
        questions: formData[GAME_FORM_STRUCT.QUESTIONS]
          .split('\n')
          .map((line: string) =>
            line
              .trim()
              .replace(/^"|"$/, '')
              .replace(/",?$/, ''),
          )
          .filter((line: string) => line.length > 0),
      },
    ];
    const formattedLegend = formData[GAME_FORM_STRUCT.LEGEND]
      .split('\n')
      .map((line: string) =>
        line
          .trim()
          .replace(/^"|"$/, '')
          .replace(/",?$/, ''),
      )
      .filter((line: string) => line.length > 0);

    let tempFormData = {
      ...formData,
      [GAME_FORM_STRUCT.QUESTIONS]: formattedQuestions,
      [GAME_FORM_STRUCT.LEGEND]: formattedLegend,
      id: gameForms.length > 0 ? gameForms[gameForms.length - 1].id + 1 : 1,
    };
    addGameForm(tempFormData);

    formRef.current.setState([GAME_FORM_STRUCT.NAME], '');
    formRef.current.setState([GAME_FORM_STRUCT.QUESTIONS], '');
    formRef.current.setState([GAME_FORM_STRUCT.LEGEND], '');
  }, [addGameForm, formRef, gameForms]);

  return (
    <>
      {/* @ts-ignore */}
      <Form ref={formRef} config={formConfig} onChange={onChangeForm} />
      <div
        className={`${styles.spaceBetweenRow} ${styles.addFormRowContainer}`}
      >
        <div className={styles.newFormTitle}>{'Новая форма'}</div>
        <Button
          key={'add_form_btn'}
          theme={THEMES.PRIMARY}
          onClick={handleAddForm}
          disabled={isAddFormButtonDisabled}
          style={undefined}
        >
          {'Добавить'}
        </Button>
      </div>
      <div className={styles.addedFormContainer}>
        {gameForms.map(form => (
          <div
            key={`game_form_${form.id}`}
            className={`${styles.spaceBetweenRow} ${styles.formItemContainer}`}
          >
            <div className={styles.addedFormTitle}>{form.name}</div>
            <div
              className={styles.removeFormLink}
              onClick={() => removeGameForm(form.id)}
            >
              {'Удалить'}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default GameForms;
