import React, { FC, useMemo } from 'react';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';

import styles from './index.module.scss';

import { Participant, PARTICIPANT_ROLE } from '../../../../types';

const ROLES_NAMES = {
  [PARTICIPANT_ROLE.MANAGER]: 'Менеджер',
  [PARTICIPANT_ROLE.REPRESENTATIVE]: 'КАМ',
};

interface GameAvailabilityProps {
  participants: Participant[];
  toggleIsConnectionsDialogVisible: () => void;
  toggleIsBreaksDialogVisible: () => void;
  setCurrentParticipant: (data: Participant) => void;
}

const GameAvailability: FC<GameAvailabilityProps> = ({
  participants,
  toggleIsConnectionsDialogVisible,
  toggleIsBreaksDialogVisible,
  setCurrentParticipant,
}) => {
  const participantsTableConfig = useMemo(() => {
    const config = [
      {
        type: TABLE_TYPES.TEXT,
        getValue: (data: Participant) => data.name,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: (data: Participant) => data.email,
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: (data: Participant) =>
          data.role ? ROLES_NAMES[data.role] : '',
      },
      {
        type: TABLE_TYPES.LINK,
        getValue: (data: Participant) =>
          data.role === PARTICIPANT_ROLE.MANAGER ? 'Перерывы' : null,
        onClick: (data: Participant) => {
          setCurrentParticipant(data);
          toggleIsBreaksDialogVisible();
        },
        getProps: (data: Participant) => ({
          className: `${styles.link} ${
            data.breaks && data.breaks.length > 0 ? styles.successLink : ''
          }`,
        }),
      },
      {
        type: TABLE_TYPES.LINK,
        getValue: (_: Participant) => 'Связи',
        onClick: (data: Participant) => {
          setCurrentParticipant(data);
          toggleIsConnectionsDialogVisible();
        },
        getProps: (data: Participant) => ({
          className: `${styles.link} ${
            data.connections.length > 0 ? styles.successLink : ''
          }`,
        }),
      },
    ];
    return config;
  }, [
    setCurrentParticipant,
    toggleIsBreaksDialogVisible,
    toggleIsConnectionsDialogVisible,
  ]);

  return (
    <div>
      {participants.length > 0 && (
        <Table
          className={styles.participantsTable}
          headers={['ФИО', 'Email', 'Роль', '', '']}
          filters={[0, 1, 2]}
          config={participantsTableConfig}
          data={participants}
        />
      )}
    </div>
  );
};

export default GameAvailability;
