import React from 'react';
import { branch } from 'baobab-react/higher-order';

import {
  clientInfoSelector,
  institutionsSelector,
  positionsSelector,
  logsSelector,
} from '../../../store/struct/selectors';
import LOG_STRUCT from '../../../store/struct/calculated/log';
import { getDate, getTime } from '../../../utils/time';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import Select from '../../../components/form/select';

import styles from './index.module.scss';
import { logsLoadingSelector } from '../../../store/struct/app/selectors';
import { Loader } from '../../../components/loader';

const HEADERS = [
  'Дата',
  'Время',
  'Роль',
  'Пользователь',
  'Действие',
  'Сущность',
  'ID встречи',
  'Данные',
];

const LogsComponent = props => {
  const tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => getDate(data[LOG_STRUCT.DATETIME]),
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => getTime(data[LOG_STRUCT.DATETIME]),
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[LOG_STRUCT.ROLE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[LOG_STRUCT.USER],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[LOG_STRUCT.ACTION],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[LOG_STRUCT.ENTITY],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => data[LOG_STRUCT.MEETING_ID],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: data =>
        data[LOG_STRUCT.DATA].map((line, index) => (
          <div key={index}>
            {line[0]}: {line[1]}
          </div>
        )),
    },
  ];

  const {
    logs,
    gamesOptions,
    currentGameId,
    setCurrentGameId,
    logsLoading,
  } = props;

  return (
    <>
      <div className={styles.block}>
        <Select
          onChange={setCurrentGameId}
          options={gamesOptions}
          value={currentGameId}
          attrs={{ disabled: gamesOptions.length === 0 }}
          placeholder='Выбрать игру'
        />
      </div>
      {logsLoading ? (
        <Loader />
      ) : currentGameId ? (
        <Table
          className={styles.logTable}
          headers={HEADERS}
          config={tableConfig}
          keyName={LOG_STRUCT.ID}
          filters={[0, 2, 3, 4, 5, 6]}
          data={logs.filter(
            log => log[LOG_STRUCT.GAME_ID] === Number(currentGameId),
          )}
        />
      ) : (
        <div>
          Для того, чтобы открыть журнал действий пользователей, необходимо
          выбрать игру.
        </div>
      )}
    </>
  );
};

export default branch(
  {
    logs: logsSelector(),
    positions: positionsSelector(),
    institutions: institutionsSelector(),
    clientInfo: clientInfoSelector(),
    logsLoading: logsLoadingSelector(),
  },
  LogsComponent,
);
