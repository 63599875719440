const STRUCT = {
  DESC: 'desc',
  EXERCISE_ID: 'exercise_id',
  HIDDEN: 'hidden',
  ID: 'id',
  NAME: 'name',
  RULES: 'rules',
  STATUS_ID: 'status_id',
  DAYS: 'days',
  HOURS: 'hours',
  BREAKS: 'breaks',
  MEETING_LENGTH: 'meetingLength',
  MEETING_CONFIRMATION_TIME: 'meetingConfirmationTime',
  MEETING_BREAK: 'meetingBreak',
  POSITIONS: 'positions',
  INSTITUTIONS: 'institutions',
};

export const GAME_STATUS = {
  DEFAULT: 1,
  PLAYING: 2,
  FINISHED: 3,
};

export default STRUCT;
