import React, { FC, useMemo } from 'react';

import { SUBTYPES } from '../../../../components/form/input';

import Form, { TYPES as FORM_TYPES } from '../../../../components/form';

import GAME_STRUCT from '../../../../store/struct/entities/game';

import styles from './index.module.scss';

interface GameGeneralProps {
  formRef: React.Ref<any>;
  errorValidationDays: boolean;
  errorValidationHours: boolean;
  errorValidationBreaks: boolean;
  errorValidationBreaksText: string;
  errorValidationMeetingLength: boolean;
  errorValidationMeetingBreak: boolean;
  errorValidationMeetingConfirmationTime: boolean;
  validateDays: (value: Date[]) => void;
  validateHours: (value: Date[]) => void;
  validateBreaks: (value: string) => void;
  handleGeneralFormData: (state: any) => void;
  validateMeetingLength: (value: number) => void;
  validateMeetingBreak: (value: number) => void;
  validateMeetingConfirmationTime: (value: number) => void;
}

const GameGeneral: FC<GameGeneralProps> = ({
  formRef,
  errorValidationDays,
  errorValidationHours,
  errorValidationBreaks,
  errorValidationBreaksText,
  errorValidationMeetingLength,
  errorValidationMeetingBreak,
  errorValidationMeetingConfirmationTime,
  validateDays,
  validateHours,
  validateBreaks,
  validateMeetingLength,
  validateMeetingBreak,
  validateMeetingConfirmationTime,
  handleGeneralFormData,
}) => {
  const formConfig = useMemo(() => {
    const config = [
      {
        id: GAME_STRUCT.NAME,
        title: 'Название игры',
        type: FORM_TYPES.INPUT,
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: GAME_STRUCT.DAYS,
        title: 'Дни проведения игры',
        type: FORM_TYPES.DATEPERIOD,
        attrs: {
          startDate: {
            type: 'number',
            min: 0,
            minDate: new Date(),
          },
          endDate: {
            type: 'number',
            min: 0,
            minDate: new Date(),
          },
        },
        className: `${errorValidationDays ? styles.errorInputContainer : ''}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}
        ${errorValidationDays ? styles.error : ''}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
        validationFunc: validateDays,
      },
      {
        id: GAME_STRUCT.HOURS,
        title: 'Часы проведения игры',
        type: FORM_TYPES.TIMEPERIOD,
        attrs: {
          startTime: {
            type: 'number',
            min: 0,
          },
          endTime: {
            type: 'number',
            min: 0,
          },
        },
        className: `${errorValidationHours ? styles.errorInputContainer : ''}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}
        ${errorValidationHours ? styles.error : ''}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
        validationFunc: validateHours,
      },
      {
        id: GAME_STRUCT.DESC,
        title: 'Описание игры',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        initialValue: '',
        attrs: {
          rows: 5,
        },
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: GAME_STRUCT.RULES,
        title: 'Правила игры',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        initialValue: '',
        attrs: {
          rows: 4,
        },
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: GAME_STRUCT.BREAKS,
        title: 'Перерывы игры',
        type: FORM_TYPES.INPUT,
        titleUnder: errorValidationBreaks ? errorValidationBreaksText : '',
        classNameLabelUnder: `${errorValidationBreaks ? styles.error : ''}`,
        className: `${styles.inputBorders} ${styles.maxInputWidth} ${
          errorValidationBreaks ? styles.errorInputContainer : ''
        }`,
        classNameLabel: `${styles.titleText} ${styles.marginRight} ${
          errorValidationBreaks ? styles.error : ''
        }`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
        validationFunc: validateBreaks,
      },
      {
        id: GAME_STRUCT.MEETING_LENGTH,
        title: 'Длительность встречи (мин)',
        type: FORM_TYPES.INPUT,
        attrs: {
          type: 'number',
          min: 0,
        },
        className: `${styles.inputBorders} ${styles.narrowInput} ${
          errorValidationMeetingLength ? styles.errorInputContainer : ''
        }`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}
        ${errorValidationMeetingLength ? styles.error : ''}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
        validationFunc: validateMeetingLength,
      },
      {
        id: GAME_STRUCT.MEETING_BREAK,
        title: 'Перерыв между встречами (мин)',
        type: FORM_TYPES.INPUT,
        attrs: {
          type: 'number',
          min: 0,
        },
        className: `${styles.inputBorders} ${styles.narrowInput} ${
          errorValidationMeetingBreak ? styles.errorInputContainer : ''
        }`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}
        ${errorValidationMeetingBreak ? styles.error : ''}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
        validationFunc: validateMeetingBreak,
      },
      {
        id: GAME_STRUCT.MEETING_CONFIRMATION_TIME,
        title: 'Подтверждение встречи клиентом (ч)',
        type: FORM_TYPES.INPUT,
        attrs: {
          type: 'number',
          min: 0,
        },
        className: `${styles.inputBorders} ${styles.narrowInput} ${
          errorValidationMeetingConfirmationTime
            ? styles.errorInputContainer
            : ''
        }`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}
        ${errorValidationMeetingConfirmationTime ? styles.error : ''}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
        validationFunc: validateMeetingConfirmationTime,
      },
      {
        id: GAME_STRUCT.POSITIONS,
        title: 'Должности',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        attrs: {
          rows: 4,
        },
        titleUnder: 'Каждую должность вводите с новой строки',
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
      {
        id: GAME_STRUCT.INSTITUTIONS,
        title: 'Учреждения',
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        attrs: {
          rows: 4,
        },
        titleUnder: 'Каждое учреждение вводите с новой строки',
        className: `${styles.inputBorders} ${styles.maxInputWidth}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        formComponentWrapperClassName: styles.maxInputWidth,
      },
    ];
    return config;
  }, [
    errorValidationBreaks,
    errorValidationBreaksText,
    errorValidationDays,
    errorValidationHours,
    errorValidationMeetingBreak,
    errorValidationMeetingConfirmationTime,
    errorValidationMeetingLength,
    validateBreaks,
    validateDays,
    validateHours,
    validateMeetingBreak,
    validateMeetingConfirmationTime,
    validateMeetingLength,
  ]);

  const onChangeForm = (state: any) => {
    handleGeneralFormData(state);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Form ref={formRef} config={formConfig} onChange={onChangeForm} />
    </>
  );
};

export default GameGeneral;
